var textures = {
  circles: function () {
    var background, circles, complement, fill, id, radius, size, stroke, strokeWidth;
    var xmlns = 'http://www.w3.org/2000/svg';
    size = 20;
    background = '';
    radius = 2;
    complement = false;
    fill = '#fff';
    stroke = '#fff';
    strokeWidth = 0;
    id = (Math.random().toString(36) + '00000000000000000').replace(/[^a-z]+/g, '').slice(0, 5);
    circles = function () {
    };
    circles.heavier = function (_) {
      if (!arguments.length) {
        radius = radius * 2
      } else {
        radius = _ ? radius * 2 * _ : radius * 2
      }
      return circles
    };
    circles.lighter = function (_) {
      if (!arguments.length) {
        radius = radius / 2
      } else {
        radius = _ ? radius / (2 * _) : radius / 2
      }
      return circles
    };
    circles.thinner = function (_) {
      if (!arguments.length) {
        size = size * 2
      } else {
        size = _ ? size * 2 * _ : size * 2
      }
      return circles
    };
    circles.thicker = function (_) {
      if (!arguments.length) {
        size = size / 2
      } else {
        size = _ ? size / (2 * _) : size / 2
      }
      return circles
    };
    circles.background = function (_) {
      background = _;
      return circles
    };
    circles.size = function (_) {
      size = _;
      return circles
    };
    circles.complement = function () {
      complement = true;
      return circles
    };
    circles.radius = function (_) {
      radius = _;
      return circles
    };
    circles.fill = function (_) {
      fill = _;
      return circles
    };
    circles.stroke = function (_) {
      stroke = _;
      return circles
    };
    circles.strokeWidth = function (_) {
      strokeWidth = _;
      return circles
    };
    circles.id = function (_) {
      if (!arguments.length) {
        return id
      } else {
        id = _;
        return circles
      }
    };
    circles.url = function () {
      return 'url(#' + id + ')'
    };
    circles.getNode = function () {
      var corner, defs, pattern, rect, circle, i, len, ref;
      defs = document.createElementNS(xmlns, 'defs');
      pattern = document.createElementNS(xmlns, 'pattern');
      pattern.setAttributeNS(null, 'id', 'pattern-' + id);
      pattern.setAttributeNS(null, 'patternUnits', 'userSpaceOnUse');
      pattern.setAttributeNS(null, 'width', size);
      pattern.setAttributeNS(null, 'height', size);
      if (background) {
        rect = document.createElementNS(xmlns, 'rect');
        rect.setAttributeNS(null, 'fill', background);
        rect.setAttributeNS(null, 'width', size);
        rect.setAttributeNS(null, 'height', size);
        pattern.appendChild(rect)
      }
      if (complement) {
        ref = [
          [
            0,
            size / 2
          ],
          [
            size / 2,
            0
          ],
          [
            size,
            size / 2
          ],
          [
            size / 2,
            size
          ]
        ];
        for (i = 0, len = ref.length; i < len; i += 1) {
          corner = ref[i];
          circle = document.createElementNS(xmlns, 'circle');
          circle.setAttributeNS(null, 'cx', corner[0]);
          circle.setAttributeNS(null, 'cy', corner[1]);
          circle.setAttributeNS(null, 'r', radius);
          circle.setAttributeNS(null, 'fill', fill);
          circle.setAttributeNS(null, 'stroke', stroke);
          circle.setAttributeNS(null, 'stroke-width', strokeWidth);
          pattern.appendChild(circle)
        }
      } else {
        circle = document.createElementNS(xmlns, 'circle');
        circle.setAttributeNS(null, 'cx', size / 2);
        circle.setAttributeNS(null, 'cy', size / 2);
        circle.setAttributeNS(null, 'r', radius);
        circle.setAttributeNS(null, 'fill', fill);
        circle.setAttributeNS(null, 'stroke', stroke);
        circle.setAttributeNS(null, 'stroke-width', strokeWidth);
        pattern.appendChild(circle)
      }
      defs.appendChild(pattern);
      var mask = document.createElementNS(xmlns, 'mask');
      mask.setAttributeNS(null, 'id', 'mask-' + id);
      var mask_rect = document.createElementNS(xmlns, 'rect');
      mask_rect.setAttributeNS(null, 'x', '0');
      mask_rect.setAttributeNS(null, 'y', '0');
      mask_rect.setAttributeNS(null, 'width', '100%');
      mask_rect.setAttributeNS(null, 'height', '100%');
      mask_rect.setAttributeNS(null, 'fill', 'url(#' + 'pattern-' + id + ')');
      defs.appendChild(mask).appendChild(mask_rect);
      return defs
    };
    return circles
  },
  lines: function () {
    var background, id, lines, orientation, getPath, shapeRendering, size, stroke, strokeWidth;
    var xmlns = 'http://www.w3.org/2000/svg';
    size = 20;
    strokeWidth = 2;
    stroke = '#fff';
    id = (Math.random().toString(36) + '00000000000000000').replace(/[^a-z]+/g, '').slice(0, 5);
    background = '';
    orientation = ['diagonal'];
    shapeRendering = 'auto';
    getPath = function (orientation) {
      switch (orientation) {
      case '0/8':
        return function (s) {
          return 'M ' + s / 2 + ', 0 l 0, ' + s
        }(size);
      case 'vertical':
        return function (s) {
          return 'M ' + s / 2 + ', 0 l 0, ' + s
        }(size);
      case '1/8':
        return function (s) {
          return 'M ' + s / 4 + ',0 l ' + s / 2 + ',' + s + ' M ' + -s / 4 + ',0 l ' + s / 2 + ',' + s + '\nM ' + s * 3 / 4 + ',0 l ' + s / 2 + ',' + s
        }(size);
      case '2/8':
        return function (s) {
          return 'M 0,' + s + ' l ' + s + ',' + -s + ' M ' + -s / 4 + ',' + s / 4 + ' l ' + s / 2 + ',' + -s / 2 + '\nM ' + 3 / 4 * s + ',' + 5 / 4 * s + ' l ' + s / 2 + ',' + -s / 2
        }(size);
      case 'diagonal':
        return function (s) {
          return 'M 0,' + s + ' l ' + s + ',' + -s + ' M ' + -s / 4 + ',' + s / 4 + ' l ' + s / 2 + ',' + -s / 2 + '\nM ' + 3 / 4 * s + ',' + 5 / 4 * s + ' l ' + s / 2 + ',' + -s / 2
        }(size);
      case '3/8':
        return function (s) {
          return 'M 0,' + 3 / 4 * s + ' l ' + s + ',' + -s / 2 + ' M 0,' + s / 4 + ' l ' + s + ',' + -s / 2 + '\nM 0,' + s * 5 / 4 + ' l ' + s + ',' + -s / 2
        }(size);
      case '4/8':
        return function (s) {
          return 'M 0,' + s / 2 + ' l ' + s + ',0'
        }(size);
      case 'horizontal':
        return function (s) {
          return 'M 0,' + s / 2 + ' l ' + s + ',0'
        }(size);
      case '5/8':
        return function (s) {
          return 'M 0,' + -s / 4 + ' l ' + s + ',' + s / 2 + 'M 0,' + s / 4 + ' l ' + s + ',' + s / 2 + '\nM 0,' + s * 3 / 4 + ' l ' + s + ',' + s / 2
        }(size);
      case '6/8':
        return function (s) {
          return 'M 0,0 l ' + s + ',' + s + ' M ' + -s / 4 + ',' + 3 / 4 * s + ' l ' + s / 2 + ',' + s / 2 + '\nM ' + s * 3 / 4 + ',' + -s / 4 + ' l ' + s / 2 + ',' + s / 2
        }(size);
      case '7/8':
        return function (s) {
          return 'M ' + -s / 4 + ',0 l ' + s / 2 + ',' + s + ' M ' + s / 4 + ',0 l ' + s / 2 + ',' + s + '\nM ' + s * 3 / 4 + ',0 l ' + s / 2 + ',' + s
        }(size);
      default:
        return function (s) {
          return 'M ' + s / 2 + ', 0 l 0, ' + s
        }(size)
      }
    };
    lines = function () {
    };
    lines.background = function (_) {
      background = _;
      return lines
    };
    lines.shapeRendering = function (_) {
      shapeRendering = _;
      return lines
    };
    lines.heavier = function (_) {
      if (!arguments.length) {
        strokeWidth = strokeWidth * 2
      } else {
        strokeWidth = _ ? strokeWidth * 2 * _ : strokeWidth * 2
      }
      return lines
    };
    lines.lighter = function (_) {
      if (!arguments.length) {
        strokeWidth = strokeWidth / 2
      } else {
        strokeWidth = _ ? strokeWidth / (2 * _) : strokeWidth / 2
      }
      return lines
    };
    lines.thinner = function (_) {
      if (!arguments.length) {
        size = size * 2
      } else {
        size = _ ? size * 2 * _ : size * 2
      }
      return lines
    };
    lines.thicker = function (_) {
      if (!arguments.length) {
        size = size / 2
      } else {
        size = _ ? size / (2 * _) : size / 2
      }
      return lines
    };
    lines.orientation = function () {
      var args;
      args = 1 <= arguments.length ? Array.prototype.slice.call(arguments, 0) : [];
      orientation = args;
      return lines
    };
    lines.size = function (_) {
      size = _;
      return lines
    };
    lines.stroke = function (_) {
      stroke = _;
      return lines
    };
    lines.strokeWidth = function (_) {
      strokeWidth = _;
      return lines
    };
    lines.id = function (_) {
      if (!arguments.length) {
        return id
      } else {
        id = _;
        return lines
      }
    };
    lines.url = function () {
      return 'url(#' + id + ')'
    };
    lines.getNode = function () {
      var defs, pattern, rect, i, o, len;
      defs = document.createElementNS(xmlns, 'defs');
      pattern = document.createElementNS(xmlns, 'pattern');
      pattern.setAttributeNS(null, 'id', 'pattern-' + id);
      pattern.setAttributeNS(null, 'patternUnits', 'userSpaceOnUse');
      pattern.setAttributeNS(null, 'width', size);
      pattern.setAttributeNS(null, 'height', size);
      if (background) {
        rect = document.createElementNS(xmlns, 'rect');
        rect.setAttributeNS(null, 'fill', background);
        rect.setAttributeNS(null, 'width', size);
        rect.setAttributeNS(null, 'height', size);
        pattern.appendChild(rect)
      }
      for (i = 0, len = orientation.length; i < len; i += 1) {
        o = orientation[i];
        var path = document.createElementNS(xmlns, 'path');
        path.setAttributeNS(null, 'd', getPath(o));
        path.setAttributeNS(null, 'stroke-width', strokeWidth);
        path.setAttributeNS(null, 'shape-rendering', shapeRendering);
        path.setAttributeNS(null, 'stroke', stroke);
        path.setAttributeNS(null, 'stroke-linecap', 'square');
        pattern.appendChild(path)
      }
      defs.appendChild(pattern);
      var mask = document.createElementNS(xmlns, 'mask');
      mask.setAttributeNS(null, 'id', 'mask-' + id);
      mask.setAttributeNS(null, 'maskUnits', 'userSpaceOnUse');
      var mask_rect = document.createElementNS(xmlns, 'rect');
      mask_rect.setAttributeNS(null, 'x', '0');
      mask_rect.setAttributeNS(null, 'y', '0');
      mask_rect.setAttributeNS(null, 'width', '100%');
      mask_rect.setAttributeNS(null, 'height', '100%');
      mask_rect.setAttributeNS(null, 'fill', 'url(#' + 'pattern-' + id + ')');
      defs.appendChild(mask).appendChild(mask_rect);
      return defs
    };
    return lines
  },
  paths: function () {
    var background, d, fill, defs, height, id, paths, pattern, shapeRendering, size, stroke, rect, strokeWidth, svgPath, width;
    var xmlns = 'http://www.w3.org/2000/svg';
    size = 20;
    height = 1;
    width = 1;
    strokeWidth = 2;
    stroke = '#fff';
    background = '';
    d = '';
    shapeRendering = 'auto';
    fill = 'transparent';
    id = void 0;
    svgPath = function (_) {
      switch (_) {
      case 'squares':
        return function (s) {
          return 'M ' + s / 4 + ' ' + s / 4 + ' l ' + s / 2 + ' 0 l 0 ' + s / 2 + ' l ' + -s / 2 + ' 0 Z'
        }(size);
      case 'nylon':
        return function (s) {
          return 'M 0 ' + s / 4 + ' l ' + s / 4 + ' 0 l 0 ' + -s / 4 + ' M ' + s * 3 / 4 + ' ' + s + ' l 0 ' + -s / 4 + '\nl ' + s / 4 + ' 0 M ' + s / 4 + ' ' + s / 2 + ' l 0 ' + s / 4 + ' l ' + s / 4 + ' 0 M ' + s / 2 + ' ' + s / 4 + '\nl ' + s / 4 + ' 0 l 0 ' + s / 4
        }(size);
      case 'waves':
        return function (s) {
          return 'M 0 ' + s / 2 + ' c ' + s / 8 + ' ' + -s / 4 + ' , ' + s * 3 / 8 + ' ' + -s / 4 + ' , ' + s / 2 + ' 0\nc ' + s / 8 + ' ' + s / 4 + ' , ' + s * 3 / 8 + ' ' + s / 4 + ' , ' + s / 2 + ' 0 M ' + -s / 2 + ' ' + s / 2 + '\nc ' + s / 8 + ' ' + s / 4 + ' , ' + s * 3 / 8 + ' ' + s / 4 + ' , ' + s / 2 + ' 0 M ' + s + ' ' + s / 2 + '\nc ' + s / 8 + ' ' + -s / 4 + ' , ' + s * 3 / 8 + ' ' + -s / 4 + ' , ' + s / 2 + ' 0'
        }(size);
      case 'woven':
        return function (s) {
          return 'M ' + s / 4 + ',' + s / 4 + 'l' + s / 2 + ',' + s / 2 + 'M' + s * 3 / 4 + ',' + s / 4 + 'l' + s / 2 + ',' + -s / 2 + '\nM' + s / 4 + ',' + s * 3 / 4 + 'l' + -s / 2 + ',' + s / 2 + 'M' + s * 3 / 4 + ',' + s * 5 / 4 + 'l' + s / 2 + ',' + -s / 2 + '\nM' + -s / 4 + ',' + s / 4 + 'l' + s / 2 + ',' + -s / 2
        }(size);
      case 'crosses':
        return function (s) {
          return 'M ' + s / 4 + ',' + s / 4 + 'l' + s / 2 + ',' + s / 2 + 'M' + s / 4 + ',' + s * 3 / 4 + 'l' + s / 2 + ',' + -s / 2
        }(size);
      case 'caps':
        return function (s) {
          return 'M ' + s / 4 + ',' + s * 3 / 4 + 'l' + s / 4 + ',' + -s / 2 + 'l' + s / 4 + ',' + s / 2
        }(size);
      case 'hexagons':
        return function (s) {
          width = 3;
          height = Math.sqrt(3);
          return 'M ' + s + ',0 l ' + s + ',0 l ' + s / 2 + ',' + s * Math.sqrt(3) / 2 + '\nl ' + -s / 2 + ',' + s * Math.sqrt(3) / 2 + ' l ' + -s + ',0\nl ' + -s / 2 + ',' + -s * Math.sqrt(3) / 2 + ' Z M 0,' + s * Math.sqrt(3) / 2 + '\nl ' + s / 2 + ',0 M ' + 3 * s + ',' + s * Math.sqrt(3) / 2 + ' l ' + -s / 2 + ',0'
        }(size);
      default:
        return _(size)
      }
    };
    paths = function () {
    };
    paths.background = function (_) {
      background = _;
      return paths
    };
    paths.shapeRendering = function (_) {
      shapeRendering = _;
      return paths
    };
    paths.heavier = function (_) {
      if (!arguments.length) {
        strokeWidth = strokeWidth * 2
      } else {
        strokeWidth = _ ? strokeWidth * 2 * _ : strokeWidth * 2
      }
      return paths
    };
    paths.lighter = function (_) {
      if (!arguments.length) {
        strokeWidth = strokeWidth / 2
      } else {
        strokeWidth = _ ? strokeWidth / (2 * _) : strokeWidth / 2
      }
      return paths
    };
    paths.thinner = function (_) {
      if (!arguments.length) {
        size = size * 2
      } else {
        size = _ ? size * 2 * _ : size * 2
      }
      return paths
    };
    paths.thicker = function (_) {
      if (!arguments.length) {
        size = size / 2
      } else {
        size = _ ? size / (2 * _) : size / 2
      }
      return paths
    };
    paths.d = function (_) {
      d = _;
      return paths
    };
    paths.size = function (_) {
      size = _;
      return paths
    };
    paths.stroke = function (_) {
      stroke = _;
      return paths
    };
    paths.strokeWidth = function (_) {
      strokeWidth = _;
      return paths
    };
    paths.id = function (_) {
      if (!arguments.length) {
        return id
      } else {
        id = _;
        return paths
      }
    };
    paths.url = function () {
      return 'url(#' + id + ')'
    };
    paths.getNode = function () {
      defs = document.createElementNS(xmlns, 'defs');
      pattern = document.createElementNS(xmlns, 'pattern');
      pattern.setAttributeNS(null, 'id', 'pattern-' + id);
      pattern.setAttributeNS(null, 'patternUnits', 'userSpaceOnUse');
      pattern.setAttributeNS(null, 'width', size * width);
      pattern.setAttributeNS(null, 'height', size * height);
      if (background) {
        rect = document.createElementNS(xmlns, 'rect');
        rect.setAttributeNS(null, 'fill', background);
        rect.setAttributeNS(null, 'width', size * width);
        rect.setAttributeNS(null, 'height', size * height);
        pattern.appendChild(rect)
      }
      var path = document.createElementNS(xmlns, 'path');
      path.setAttributeNS(null, 'd', svgPath(d));
      path.setAttributeNS(null, 'stroke-width', strokeWidth);
      path.setAttributeNS(null, 'shape-rendering', shapeRendering);
      path.setAttributeNS(null, 'stroke', stroke);
      path.setAttributeNS(null, 'stroke-linecap', 'square');
      pattern.appendChild(path);
      defs.appendChild(pattern);
      var mask = document.createElementNS(xmlns, 'mask');
      mask.setAttributeNS(null, 'id', 'mask-' + id);
      mask.setAttributeNS(null, 'maskUnits', 'userSpaceOnUse');
      var mask_rect = document.createElementNS(xmlns, 'rect');
      mask_rect.setAttributeNS(null, 'x', '0');
      mask_rect.setAttributeNS(null, 'y', '0');
      mask_rect.setAttributeNS(null, 'width', '100%');
      mask_rect.setAttributeNS(null, 'height', '100%');
      mask_rect.setAttributeNS(null, 'fill', 'url(#' + 'pattern-' + id + ')');
      defs.appendChild(mask).appendChild(mask_rect);
      return defs
    };
    return paths
  }
}